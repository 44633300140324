exports.GetParameterByName = function (name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

exports.AnyDateToStrDateDDMMYYYY = function (d) {
    /* test:
    let d1 = anyDateToStrDateDDMMYYYY ("27-04-1974");
    let d2 = anyDateToStrDateDDMMYYYY ("1974-04-27");
    let d3 = anyDateToStrDateDDMMYYYY ("19740427");
    */
    let ret = d;
    let s2 = d;

    if (typeof d === 'string') {

        const t = d.indexOf ('T');
        if (t > -1) {

            s2 = d.substring (0, t);
        }

        const sp = s2.split('-');
        
        if (sp.length === 3) {

            //year first?
            if (sp[0].length === 4) {
                
                ret = sp[2] + '-' + sp[1] + '-' + sp[0];
            } else {

                ret = sp[0] + '-' + sp[1] + '-' + sp[2];
            }
        }
    }
    else if (typeof d === 'object' && d instanceof Date) {

        return dateDDMMYYYY (d);
    }
    
    return ret;
}

function dateDDMMYYYY (d) {

    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [day, month, year].join('-');
}
exports.DateDDMMYYYY = dateDDMMYYYY;


function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

function anyStringToDate (pstr, minyear, verificar) {

    // 2019-04-02 -> 02-04-2019
    // 02-04-2019 -> 02-04-2019

    const my = minyear || 0;
    const vr = !!verificar || false;

    if (pstr === null) {
        return '';
    }

    if (typeof pstr === 'undefined') {
        return '';
    }

    let yyyy = -1;
    let mm = -1;
    let dd = -1;

    let str = pstr;
    const theT = pstr.indexOf('T');
    if (theT !== -1) {
        str = pstr.substring(0, theT);
    }

    let tempsp = str.split('/');
    
    if (tempsp.length === 1) {
        tempsp = str.split('-');
    }

    if (tempsp.length === 3) {

        if (tempsp[0].length === 4) {
                
            yyyy = tempsp[0];
            mm = tempsp[1];
            dd = tempsp[2];
        } else {

            dd = tempsp[0];
            mm = tempsp[1];
            yyyy = tempsp[2];
        }
    } else {
        //Asumo YYYYMMDD
        yyyy = str.substring (0, 4);
        mm = str.substring (4, 6);
        dd = str.substring (6, 8);
    }

    mm = mm - 1;

    if (dd !== '' && parseInt(yyyy, 10) >= my) {

        if (vr) {

            const dim = daysInMonth(parseInt(mm, 10) + 1, yyyy);

            if (parseInt(dd, 10) >= 1 && parseInt(dd, 10) <= dim) {

                if (parseInt(mm, 10) >= 0 && parseInt(mm, 10) <= 11) {

                    return new Date (yyyy, mm, dd, 0, 0, 0);
                }

                return '';
            }

            return '';
        }

        return new Date (yyyy, mm, dd, 0, 0, 0);
    }

    return '';
}
exports.AnyStringToDate = anyStringToDate;

function validarNif (pdni) {

    if (pdni === null) {
        pdni = '';
    }

    var numero = '';
    var letr = '';
    var letra = '';
    var expresionRegularDni = /^\d{8}[a-zA-Z]$/;

    var dni = pdni.replace(/\s/g, '').trim();
   
    if (expresionRegularDni.test (dni) === true) {
        numero = dni.substr(0,dni.length - 1);
        letr = dni.substr(dni.length - 1, 1);
        numero %= 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr.toUpperCase()) {
            return false; //Letra incorrecta
        }
        
        return true; //DNI OK
    }

    return false; //DNI sin formato 
}
exports.ValidarNif = validarNif;

function simpleClone(obj) {

/*
let events = {"some-index": false};
let key = "some-index";

if(Object.prototype.hasOwnProperty.call(events, key)) {
    // Esto se ejecutaria sin desencadenar la excepcion
    console.log("El objeto tiene la propiedad");
}
*/

    if (null === obj || 'object' !== typeof obj) { 
        return obj;
    }
    const copia = obj.constructor();
    for (const attr in obj) {

        if(Object.prototype.hasOwnProperty.call(obj, attr)) {
            copia[attr] = obj[attr];
        }
    }
  
    return copia;
}
exports.SimpleClone = simpleClone;

function anyDateToStrDateYYYYMMDD(d) {
    let ret = d;
    let s2 = d;

    if (typeof d === 'string') {

        const t = d.indexOf ('T');
        if (t > -1) {

            s2 = d.substring (0, t);
        }

        const sp = s2.split('-');
        
        if (sp.length === 3) {

            //year first?
            if (sp[0].length === 4) {
                
                ret = sp[0] + '-' + sp[1] + '-' + sp[2];
            } else {

                ret = sp[2] + '-' + sp[1] + '-' + sp[0];
            }
        }
    }
    else if (typeof d === 'object' && d instanceof Date) {

        return dateYYYYMMDD (d);
    }

    return ret;
}
exports.AnyDateToStrDateYYYYMMDD = anyDateToStrDateYYYYMMDD;

function dateYYYYMMDD (d) {

    var month = (d.getMonth() + 1).toString();
    var day = d.getDate().toString();
    var year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}
exports.DateYYYYMMDD = dateYYYYMMDD;

function encode (input) {
    var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index 
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}
exports.Encode = encode;

function desnullize (s) {
    if (s === null) {
        return "";
    }

    return s;
}
exports.Desnullize = desnullize;

function globalSiAlojadoVerificado (item) {

    // Campos obligatorios
    const obligatorios = [
        'TIPO_DOCUMENTO',
        'NOMBRE',
        'APELLIDO1',
        'TIPO_PERSONA',
        'TIPO_DOCUMENTO',
        'FECHA_EXPED',
        'FECHA_CADUC',
        'DOMICILIO',
        'POB',
        'CP',
        'PRV',
        'PAIS_PK',
        'EMAIL'];

    for (let i = 0; i < obligatorios.length; i += 1) {

        if (isNullOrEmpty(item[obligatorios[i]])) {
            return false;
        }
    }

    //Validaciones especiales
    if (item.TIPO_PERSONA === "A" && item.FIRMA_DIG === null) {
        return false;
    }
    if (item.TIPO_PERSONA === "A" && item.TIPO_DOCUMENTO === 'D' && !validarNif(item.NUM_DOCUMENTO)) {
        return false;
    }

    return true;
}
exports.GlobalSiAlojadoVerificado = globalSiAlojadoVerificado;

function isNullOrEmpty (s) {

    if (typeof s !== 'undefined' && (s === null || s.trim() === "")) {
        return true;
    }

    return false;
}
exports.IsNullOrEmpty = isNullOrEmpty;

function validateEmail (mail) {

    return String(mail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
exports.ValidateEmail = validateEmail;