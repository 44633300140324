<template>
    <v-menu
        :close-on-content-click="false"
        v-model="isPopup"
        :nudge-right="0"
        transition="scale-transition"
        offset-y
        :disabled=true
        min-width="290px">
        <template v-slot:activator="{ on }">
        <v-text-field 
            slot="activator"
            v-on="on"
            :value="value"
            :label=label
            append-icon="arrow_drop_down"
            :prepend-inner-icon="setAppendIcon()"
            :background-color="backgroundcolor"
            hide-details
            @input="updateval($event)"
            dense
            v-mask="'##-##-####'"
            maxlength=10
            :rules="dateRules"
            :readonly="readonly">
        </v-text-field>
        </template>
        <v-date-picker 
            :readonly=readonly
            :min=min
            :max=max
            :value="assignValue()" 
            @input="isPopup = false; updateval($event)"
            :first-day-of-week="1">
        </v-date-picker>
    </v-menu>
</template>

<script>
import Lib from '@/lib/lib.js';

export default {
    name: 'WidgetDate',

    props: {
        value:{
            type: String,
            default: null
        },
        identifier:{
            type: String,
            default: ""
        },
        min: {
            type: String,
            default: ""
        },
        max: {
            type: String,
            default: ""
        },
        backgroundcolor: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            isPopup: false,

            dateRules: [
                (v) => this.getReadonly() || Lib.AnyStringToDate(v, 1900, true) !== '' || 'fecha incorrecta | wrong date' + ' (DD-MM-YYYY)'
            ],
        }
    },
    //Methods
    methods: {
        updateval (v) {

            let value = Lib.AnyDateToStrDateDDMMYYYY(v);
            this.$emit('input', value);
        },

        assignValue () {

            try {
                if (typeof this.value !== 'undefined' && this.value !== null) {

                    const s = this.value.split("-");

                    let ret = null;

                    if (s[2].length == 4) ret = [s[2], s[1], s[0]].join("-");
                    else ret = [s[0], s[1], s[2]].join("-");

                    return ret;
                }
            } catch {
                return this.value;
            }
        },

        getReadonly () {

            return this.readonly;
        },

        setAppendIcon () {

            if (this.readonly) {
                return "";
            }
            if (!this.showpreico) {
                return "";
            }
            return "edit";
        }
    }
};
</script>
