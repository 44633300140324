exports.ObjDocType = [
    {"KEY": "X", "NAME": "Otros | Other"},
    {"KEY": "D", "NAME": "DNI Español | "},
    {"KEY": "P", "NAME": "Pasaporte | Passport"},
    {"KEY": "C", "NAME": "Carné conducir | Driving license"},
    {"KEY": "I", "NAME": "Carta DNI | DNI Card "},
    {"KEY": "N", "NAME": "Permiso residencia | Residence permit"}
];

exports.ObjSexo = [
    {"KEY": "X", "NAME": "Sin especificar | Unspecified"},
    {"KEY": "F", "NAME": "Femenino | Female"},
    {"KEY": "M", "NAME": "Masculino | Male"}
];

exports.ObjTipoPersona = [
    {"KEY": "A", "NAME": "Adulto | Adult"},
    {"KEY": "N", "NAME": "Niño | Child"},
    {"KEY": "B", "NAME": "Bebé | Baby"}
];

exports.ObjPaises = []