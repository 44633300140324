<template>
    <v-dialog v-model="visible" persistent max-width="500">
        <v-card>
            <v-card-title class="headline">{{tittle}}</v-card-title>
            <v-card-text class="subheading font-weight-regular">{{message}}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click.native="$emit('responseno')"><v-icon>close</v-icon></v-btn>
                <v-btn color="green" text @click.native="$emit('responseyes')"><v-icon>done</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogYesNo',
    props:{
        visible: {
            type: Boolean,
            default: false
        },
        tittle: {
            type: String,
            default: "Tittle"
        },
        message: {
            type: String,
            default: "Message"
        }
    }
};
</script>