<template>
    <v-dialog v-model="visible" persistent width="440">
        <v-card>
            <v-card-title>Configuración</v-card-title>
            <v-card-text>
                <v-text-field class="right-input" v-model="idComplejo" label="Id de complejo"></v-text-field>
                <v-text-field class="right-input" v-model="idComplejo2" label="Id de complejos múltiples (ID1, ID2)"></v-text-field>
                <v-text-field class="right-input" v-model="idRecepcion" label="Id de recepción"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer/><v-btn @click="doKo" color="error" depressed>cancelar</v-btn><v-btn @click="doOk" color="success" depressed>aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'FormConfig',

    data () {
        return {
            visible: false,
            callbackOk: null,
            callbackKo: null,

            idComplejo: '',
            idComplejo2: '',
            idRecepcion: ''
        }
    },

    mounted () {
    },

    methods: {
        show (callbackOk, callbackKo) {

            this.callbackOk = callbackOk;
            this.callbackKo = callbackKo;

            this.idComplejo = localStorage.idComplejo;
            this.idComplejo2 = localStorage.idComplejo2;
            this.idRecepcion = localStorage.idRecepcion;

            this.visible = true;
        },

        doOk () {

            this.visible = false;

            localStorage.idComplejo = this.idComplejo;
            localStorage.idComplejo2 = this.idComplejo2;
            localStorage.idRecepcion = this.idRecepcion;

            if (this.callbackOk !== null) {

                this.callbackOk();
            }
        },

        doKo () {

            this.visible = false;

            if (this.callbackKo !== null) {

                this.callbackKo();
            }
        }
    }
};
</script>

<style scoped>
    .right-input >>> input {
        text-align: right
    }
</style>