<template>
    <v-dialog v-model="visible" fullscreen>
        <v-card>
            <v-container fluid class="ma-0 pa-0">
                <v-row no-gutters>
                <v-col  offset-md="2" md="8" xs="12">
                    <v-card>
                        <v-app-bar dense color="primary" dark>
                            <v-btn v-if="!modeCliente" color="blue" depressed @click="onSaveAll(true)"><v-icon>undo</v-icon></v-btn>
                            <v-spacer></v-spacer>
                            {{nombreComplejo}}
                            <v-spacer></v-spacer>
                            <!--
                            <v-btn v-if="dataDetalle.length === guestsCorrecto" color="success" depressed @click="onSaveAll(false);mensajeSiReceYTodoOk();">Aceptar | Accept<v-icon right>mdi-content-save-check</v-icon></v-btn>
                            -->
                            <v-btn v-if="!modeCliente" color="success" depressed @click="onSaveAll(true);mensajeSiReceYTodoOk(false);">Aceptar | Accept<v-icon right>mdi-content-save-check</v-icon></v-btn>
                        </v-app-bar>

                        <!--<v-card-title class="py-1"><p class="caption mt-1 mb-0">Hotel</p><v-spacer/><p class="title font-weight-medium mt-1 mb-0">{{nombreComplejo}}</p></v-card-title>-->
                        <v-card-title class="py-1"><p class="caption mt-1 mb-0">Reserva/Ref | Booking Id/Ref</p><v-spacer/><p class="subtitle-1 font-weight-medium mt-1 mb-0">{{dataCabecera.RESERVA_PK}} / {{dataCabecera.REFERENCIA}}</p></v-card-title>
                        <v-card-title class="py-1"><p class="caption mt-1 mb-0">Entrada/salida | Arrival/departure</p><v-spacer/><p class="subtitle-1 font-weight-medium mt-1 mb-0">{{Lib_AnyDateToStrDateDDMMYYYY(dataCabecera.FECHAENTRADA)}} / {{Lib_AnyDateToStrDateDDMMYYYY(dataCabecera.FECHASALIDA)}}</p></v-card-title>
                        <v-card-title>
                            <!--<v-btn v-show="!checkinYaRealizado||!modeCliente" color="primary" depressed @click="onAddGuest"><v-icon>person_add</v-icon></v-btn>-->
                            <v-spacer/>

                            <div v-if="modeCliente" class="ma-2">
                                <v-btn v-if="!checkinYaRealizado && puedoHacerCheckin() && dataDetalle.length > 0" block dark color="primaryAlt" 
                                    @click="onDoCheckin()">CHECK-IN
                                    <v-icon right>done</v-icon>
                                </v-btn>
                                <v-alert class="ma-0 pa-0" v-else-if="!checkinYaRealizado && dataDetalle.length > 0" type="error">No puede hacer CHECK-IN | Can't do CHECK-IN</v-alert>
                                <v-alert class="ma-0 pa-0" dense v-else-if="dataDetalle.length === 0" type="error">Ha de añadir alojados | You have to add guests</v-alert>
                            </div>

                            <v-alert v-if="modeCliente&&checkinYaRealizado" type="success">CHECK-IN realizado | CHECK-IN done</v-alert>
                            <v-spacer/>
                            <v-chip v-show="!checkinYaRealizado||!modeCliente" color="success" class="mr-2">
                                <v-icon left>thumb_up</v-icon>{{guestsCorrectos}} / {{dataDetalle.length}}
                            </v-chip>
                            <v-chip v-show="!checkinYaRealizado||!modeCliente" color="error">
                                <v-icon left>thumb_down</v-icon>{{dataDetalle.length  - guestsCorrectos}} / {{dataDetalle.length}}
                            </v-chip>
                            <!--<v-btn color="success" depressed @click="onSaveAll"><v-icon>mdi-content-save-check</v-icon></v-btn>-->
                            <!-- <v-btn color="success" depressed @click="validateGuestsForms">validar</v-btn>-->
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-show="!checkinYaRealizado||!modeCliente" style="height:calc(100vh - 198px);overflow-y:auto">
                            <template  v-for="(item, i) in dataDetalle">
                                <WidgetGuest :ref="'rowalojado'+i" :reg="item" :key="i" :counter="i+1" :total="dataDetalle.length" :modeCliente="modeCliente" class="mb-2" @reload="loadDetalle(null)" @onValidationChanged="onGuestValidationChanged"></WidgetGuest>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
                </v-row>
            </v-container>

            <DialogAccept
                :visible="dlgYesNoVisible"
                tittle="Atención | Attention"
                message="Datos correctos, puede volver a la reserva | The data is right, you can go back to the booking"
                @responseyes="onResponseYes">
            </DialogAccept>
        </v-card>
    </v-dialog>
</template>

<script>

import Bbdd from '@/lib/bbdd';
import Lib from '@/lib/lib'
import WidgetGuest from '@/widgets/WidgetGuest';
import DialogAccept from '@/views/DialogAccept';

export default {
    name: 'FormGuests',

    components: {
        WidgetGuest,
        DialogAccept
    },

    data () {
        return {
            visible: false,
            idComplejo: 0,
            reservaPk: 0,
            rowId: 0,
            modeCliente: false,

            nombreComplejo: '',

            dataCabecera: {},
            dataDetalle: [],

            guestsCorrectos: 0,

            // -----------
            canDoCheckin: false,
            canModifyData: false,
            checkinYaRealizado: false,
            estadoHabitacionSucia: false,

            dlgYesNoVisible: false,
            defaultEmail: ''
        }
    },

    mounted () {
    },

    methods: {
        show (idComplejo, reservaPk, rowId, defaultEmail, modeCliente, callbackOk, callbackKo) {

            this.visible = true;

            this.idComplejo = idComplejo;
            this.reservaPk = reservaPk;
            this.rowId = rowId;
            this.modeCliente = modeCliente;
            this.callbackOk = callbackOk;
            this.callbackKo = callbackKo;

            if (defaultEmail !== null) {
                if (Lib.ValidateEmail (defaultEmail) && defaultEmail.length > 5) {
                    if (defaultEmail.toLowerCase().indexOf('booking') === -1 && defaultEmail.toLowerCase().indexOf('expedia') === -1) {
                        this.defaultEmail = defaultEmail;
                    }
                }
            } else {
                this.defaultEmail = '';
            }

            this.dataCabecera = {};
            this.dataDetalle = [];
            this.guestsCorrectos = 0;

            Bbdd.GetComplejo(localStorage.idComplejo, (err, data)=>{
                if (err === null && data.length > 0) {
                    this.nombreComplejo = data[0].N_COMPLEJO;
                }
            });

            this.loadCabecera ( () => {
                this.loadDetalle ( () => {
                });
            });
        },

        mensajeSiReceYTodoOk (dlgVisible) {

            if (this.modeCliente === false) {

                // if (this.guestsCorrectos > 0 && this.guestsCorrectos === this.dataDetalle.length) {

                    Bbdd.SetPaperlessCheckInProcesado (this.idComplejo, this.reservaPk, this.rowId, () => {

                        this.dlgYesNoVisible = dlgVisible;
                    });
                //}
            }
        },

        onResponseYes () {

            this.dlgYesNoVisible = false;
            this.doOk ();
        },

        loadCabecera (callback) {

            Bbdd.GetRawReserva (this.idComplejo, this.reservaPk, (err, data)=>{
                if (err === null && data.length > 0) {
                    this.dataCabecera = data[0];

                    callback ();
                }
            });

            Bbdd.GetCanDoCheckin (this.reservaPk, (err, data) => {

                if (err === null && data.length === 1) {
                    this.canDoCheckin = data[0].RESPONSE;

                    if (data[0].ESTADO_HABITACION === 0) {
                        this.estadoHabitacionSucia = true;
                    }

                    //  1 Puede hacer checkin
                    // -1 ERROR -> reserva no existe
                    // -2 ERROR -> reserva ya ha realizado el checkin -> NO MODIFICAR DATOS
                    // -3 ERROR -> reserva futura
                    // -4 ERROR -> error desconocido -> NO MODIFICAR DATOS

                    if (this.canDoCheckin === 1 || this.canDoCheckin === -3) {
                        this.canModifyData = true;
                    } else {
                        this.canModifyData = false;
                    }

                    if (this.canDoCheckin === -2) {
                        this.checkinYaRealizado = true;
                    } else {
                        this.checkinYaRealizado = false;
                    }
                }
            });
        },

        loadDetalle (callback) {

            Bbdd.GetRawClientesReserva (this.reservaPk, (err, data)=>{
                if (err === null) {

                    for (let i = 0; i < data.length; i += 1 ) {

                        if (data[i].EMAIL === null || data[i].EMAIL.trim() === '' || !Lib.ValidateEmail (data[i].EMAIL.trim())) {

                            if (data[i].SI_TITULAR) {

                                data[i].EMAIL = this.defaultEmail;
                            } else {

                                data[i].EMAIL = '';
                            }
                            
                        }
                    }

                    this.dataDetalle = data;

                    this.$nextTick(function () {
                        this.validateGuestsForms ();
                        this.loadGuestsFirmas ();
                        this.loadGuestsData ();

                        if (callback !== null) {
                            callback ();
                        }
                    });
                }
            });
        },

        onSaveAll (ifExit) {

            let total = 0;

            Object.keys(this.$refs).forEach(el => {

                if (this.$refs[el][0] && typeof this.$refs[el][0].save !== 'undefined') {

                    total += 1;
                }
            });

            let conta = 0;

            Object.keys(this.$refs).forEach(el => {

                if (this.$refs[el][0] && typeof this.$refs[el][0].save !== 'undefined') {

                    this.$refs[el][0].save ( () => {

                        conta += 1;

                        if (conta === total && ifExit) {

                            this.doOk ();
                        }
                    });
                }
            });
        },

        onGuestValidationChanged () {

            this.validateGuestsForms();
        },

        validateGuestsForms () {

            this.guestsCorrectos = 0;

            Object.keys(this.$refs).forEach(el => {

                if (this.$refs[el][0] && typeof this.$refs[el][0].onValidateData !== 'undefined') {

                    this.guestsCorrectos += this.$refs[el][0].onValidateData()?1:0;
                }
            });
        },

        loadGuestsFirmas () {

            Object.keys(this.$refs).forEach(el => {

                if (this.$refs[el][0] && typeof this.$refs[el][0].loadFirma !== 'undefined') {

                    this.$refs[el][0].loadFirma();
                }
            });
        },

        loadGuestsData () {

            Object.keys(this.$refs).forEach(el => {

                if (this.$refs[el][0] && typeof this.$refs[el][0].loadData !== 'undefined') {

                    this.$refs[el][0].loadData();
                }
            });
        },

        onAddGuest () {

            Bbdd.InsertarAlojado (this.reservaPk, (err)=>{
                if (err === null) {
                    this.loadDetalle (null);
                }
            });
        },

        doOk () {

            this.visible = false;

            if (this.callbackOk !== null) {

                this.callbackOk();
            }
        },

        doKo () {

            this.visible = false;

            if (this.callbackKo !== null) {

                this.callbackKo();
            }
        },

        // ---
        
        alojadoVerificado (item) {

            return Lib.GlobalSiAlojadoVerificado (item);
        },

        puedoHacerCheckin () {

            if (this.canDoCheckin === 1 || this.canDoCheckin === -3) {

                for (let i = 0; i < this.dataDetalle.length; i += 1) {

                    if (!this.alojadoVerificado(this.dataDetalle[i])) {

                        return false;
                    }
                }

                return true;
            }

            return false;
        },
    }
};
</script>

<style scoped>
</style>