<template>
    <v-dialog v-model="visible" persistent max-width="500">
        <v-card>
            <v-card-title class="headline mb-4">{{tittle}}</v-card-title>
            <v-card-text>
                <v-btn block x-large color="primary" text @click.native="visible= false; callbackOk(0);">{{opt1}}</v-btn>
                <v-btn block x-large color="primary" text @click.native="visible= false; callbackOk(1);">{{opt2}}</v-btn>
                <v-btn block x-large color="primary" text @click.native="visible= false; callbackOk(2);">{{opt3}}</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogThreeOptions',

    data () {
        return {
            visible: false,
            tittle: '',
            callbackOk: null,
            callbackKo: null,

            opt1: '',
            opt2: '',
            opt3: ''
        }
    },
    
    methods: {

        show (tittle, opt1, opt2, opt3,  callbackOk, callbackKo) {

            this.tittle = tittle;
            this.opt1 = opt1;
            this.opt2 = opt2;
            this.opt3 = opt3;
            this.callbackOk = callbackOk;
            this.callbackKo = callbackKo;

            this.visible = true;
        }
    }
};
</script>