<template>
    <v-dialog v-model="visible" fullscreen>
        <v-card>

            <v-app-bar dense color="primary" dark>
                <v-btn color="blue" depressed @click="doKo"><v-icon>undo</v-icon></v-btn>
                <v-spacer></v-spacer>
                Firma | Signature
                <v-spacer></v-spacer>
            </v-app-bar>

            <v-card class="ma-3">
                <v-card-text>
                    <v-layout justify-center>
                        <p v-if="!rgpdAceptada" class="title" dark><v-icon left color="warning">close</v-icon>Ha de adeptar la RGPD | You must accept RGPD</p>
                    </v-layout>
                    <v-layout justify-center>
                        <v-btn v-if="!rgpdAceptada" class="mx-2 subtitle-1" depressed color="primary" dark @click="onVerRgpd('ES')">GRPD
                            <v-img class="shrink ml-2" contain src="@/assets/flags/flag_spain_70x70.png" width="32"/>
                        </v-btn>
                        <v-btn v-if="!rgpdAceptada" class="mx-2" depressed color="primary" dark @click="onVerRgpd('ING')">GRPD
                            <v-img class="shrink ml-2" contain src="@/assets/flags/flag_uk_70x70.png" width="32"/>
                        </v-btn>
                    </v-layout>
                    <p v-if="rgpdAceptada" class="green--text ma-0" dark><v-icon left color="green">done</v-icon>RGPD leida y aceptada | RGPD readed and accepted</p>
                </v-card-text>
            </v-card>
            
            <canvas v-show="rgpdAceptada" 
                v-resize="resizeCanvas"
                ref="signaturecanvas"
                style="height:calc(40vh);overflow-y:hidden;width:calc(100vw - 38px);overflow-x:hidden;margin:10px;border:2px #aaa solid;border-radius: 8px;"
               
                ></canvas>

            <v-card-actions class="pt-0 px-3" v-if="rgpdAceptada">
                <v-btn depressed @click="onBorrar()" color="primary">Limpiar | Reset<v-icon right>delete</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed @click="doOk()" color="success">Aceptar | Accept<v-icon right>done</v-icon></v-btn>
            </v-card-actions>

            <v-dialog v-model="showDlg" scrollable fullscreen>
            
                <v-card>
                    <v-card-title class="headline">Política de datos | Data policy</v-card-title>
                    <v-card-text v-html='rgpd'></v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" x-large @click="showDlg = false"><v-icon color="white">close</v-icon></v-btn>
                    <v-btn color="green" x-large @click="showDlg = false; rgpdAceptada = true"><v-icon color="white">done</v-icon></v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-card>
    </v-dialog>
</template>

<script>

import Bbdd from '@/lib/bbdd';
import SignaturePad from 'signature_pad';

export default {
    name: 'FormSignature',

    data () {
        return {
            visible: false,
            callbackOk: null,
            callbackKo: null,
            canvas: null,
            ctrlSignaturePad: null,
            rgpdAceptada: false,
            rgpd: '',
            showDlg: false
        }
    },

    updated () {

        this.$nextTick(function () {
            this.resizeCanvas();
            this.loadData();
        });
    },

    mounted () {
    },

    methods: {
        show (siLopdFirmado, callbackOk, callbackKo) {

            this.rgpdAceptada = siLopdFirmado;
            this.callbackOk = callbackOk;
            this.callbackKo = callbackKo;

            this.visible = true;
            this.rgpd = '';
            this.showDlg = false;
        },

        doOk() {
            
            if (!this.ctrlSignaturePad.isEmpty()) {

                const sdata = this.ctrlSignaturePad.toDataURL();
           
                this.visible = false;

                if (this.callbackOk !== null) {
                    
                    this.callbackOk('', sdata);
                }
            }
        },

        doKo () {

            this.visible = false;

            if (this.callbackKo !== null) {

                this.callbackKo();
            }
        },

        loadData () {

            if (this.canvas === null) {
                this.canvas = this.$refs.signaturecanvas;
            }

            this.ctrlSignaturePad = new SignaturePad(this.canvas, {backgroundColor: "rgb(255,255,255)"});
        },

        onBorrar () {

            this.ctrlSignaturePad.clear();
        },

        resizeCanvas() {

            if (this.canvas === null) {
                this.canvas = this.$refs.signaturecanvas;
            }

            if (this.canvas !== null) {
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                this.canvas.width = this.canvas.offsetWidth * ratio;
                this.canvas.height = this.canvas.offsetHeight * ratio;
                this.canvas.getContext("2d").scale(ratio, ratio);
            }
        },

        onVerRgpd (lang) {

            Bbdd.GetRgpdText (localStorage.idComplejo, lang, (err, data) => {
                
                if (err === null && data.length > 0) {
                    this.rgpd = data;
                    this.showDlg = true;
                }
            });
        }
    }
};
</script>

<style scoped>
</style>