<template>
    <v-card hover class="ma-0">
        <v-card-text>
            <v-form ref='form' class="pa-0" autocomplete="off">
                <v-row>

                    <v-col cols="12" class="pb-0">
                        <v-card-title class="pa-0">
                            <v-chip class="ma-2" outlined dark color="black">{{counter}} / {{total}}</v-chip>
                            <v-chip class="ma-2" v-if="validado" color="success">
                                <v-icon>thumb_up</v-icon>
                            </v-chip>
                            <v-chip class="ma-2" v-if="!validado" color="error">
                                <v-icon>thumb_down</v-icon>
                            </v-chip>
                            <v-spacer></v-spacer>
                            <!--
                            <v-btn dark depressed color="warning" @click="onQuitarAlojado()">Quitar | Remove
                                <v-icon right>delete</v-icon>
                            </v-btn>-->
                        </v-card-title>
                    </v-col>

                    <v-col cols="12">
                        <p class="ma-0 mb-1 font-weight-medium">Datos personales | Personal information</p>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-select
                            :readonly="ifDisabled()"
                            _outlined
                            hide-details
                            dense
                            v-model="reg.TIPO_DOCUMENTO"
                            :items="objDocType"
                            item-value="KEY"
                            item-text="NAME"
                            label="Tipo de documento | Document type"
                            :rules="fieldRulesCero">
                        </v-select>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field v-if="reg.TIPO_DOCUMENTO==='D'"
                            :readonly="ifDisabled()"
                            v-model="reg.NUM_DOCUMENTO" :rules="dniRules" hide-details="auto" _outlined dense _clearable
                            label="Número | number" 
                            type="text"
                            maxlength=30>
                        </v-text-field>
                        <v-text-field v-else
                            :readonly="ifDisabled()"
                            v-model="reg.NUM_DOCUMENTO" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Número | number" 
                            type="text"
                            maxlength=30>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <WidgetDate
                            :readonly="ifDisabled()"
                            label="Expecición | Expedition (DD-MM-YYYY)" 
                            :value="Lib_AnyDateToStrDateDDMMYYYY(reg.FECHA_EXPED)"
                            @input="reg.FECHA_EXPED=$event">
                        </WidgetDate>

                    </v-col>

                    <v-col cols="12" sm="6">
                        <WidgetDate
                            :readonly="ifDisabled()"
                            label="Caducidad | Expiration (DD-MM-YYYY)" 
                            :value="Lib_AnyDateToStrDateDDMMYYYY(reg.FECHA_CADUC)"
                            @input="reg.FECHA_CADUC=$event">
                        </WidgetDate>
                    </v-col>
                
                    <v-col cols="12" sm="6">
                        <v-select
                            :readonly="ifDisabled()"
                            _outlined
                            hide-details
                            dense
                            v-model="reg.SEXO"
                            :items="objSexo"
                            item-value="KEY"
                            item-text="NAME"
                            label="Sexo | Sex"
                            :rules="fieldRulesCero">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            :readonly="ifDisabled()"
                            _outlined
                            hide-details
                            dense
                            v-model="reg.TIPO_PERSONA"
                            :items="objTipoPersona"
                            item-value="KEY"
                            item-text="NAME"
                            label="Rango de edad | Age range"
                            :rules="fieldRulesCero">
                        </v-select>
                    </v-col>
                    <v-col cols="12" _sm="3" class="pt-2" v-if="sihaceFaltaFirma()">
                        <v-btn  @click="onClickSignature()" depressed color="error" block>Aceptar | Accept<v-icon right>thumb_up_alt</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-2" v-else-if="siRepetirFirma()">
                        <v-btn @click="onClickSignature()" depressed color="success" block>Firmar de nuevo | Sign again<v-icon></v-icon></v-btn>
                    </v-col>

                    <v-col cols="12" v-show="reg.FIRMA_DIG !== null && this.reg.FIRMA_DIG !== 0 && reg.TIPO_PERSONA === 'A'">
                        <img :id="'imageFirma' + counter" style='height: 100%; width: 100%; object-fit: contain'>
                    </v-col>
                
                    <v-col cols="12" sm="6">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.NOMBRE" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Nombre | First name" 
                            type="text"
                            autocomplete="given-name"
                            name="givenname"
                            maxlength=50>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.APELLIDO1" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Apellido 1 | Surname 1" 
                            type="text"
                            autocomplete="additional-name"
                            name="additionalname"
                            maxlength=50>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.APELLIDO2" hide-details="auto" _outlined dense _clearable
                            label="Apellido 2 | Surname 2" 
                            type="text"
                            autocomplete="family-name"
                            name="family-name"
                            maxlength=50>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <WidgetDate
                            :readonly="ifDisabled()"
                            label="Nacimiento | Birth (DD-MM-YYYY)" 
                            :value="Lib_AnyDateToStrDateDDMMYYYY(reg.NACIMIENTO)"
                            @input="reg.NACIMIENTO=$event">
                        </WidgetDate>
                    </v-col>

                    <v-col cols="12" _sm="6" class="pb-0">
                        <p class="ma-0 my-2 font-weight-medium">Datos de contacto | Contact information</p>
                    </v-col>

                    <v-col v-if="alojados.length>1 && !ifDisabled()" cols="12" _sm="6" class="mt-1 py-0">
                        <v-btn block @click="showOtrasDirecciones = true;" depressed color="primary" small>Direcciones | Addresses<v-icon small right>file_copy</v-icon></v-btn>
                    </v-col>

                    <v-col cols="12" sm="8">
                        <v-text-field class="mx-0"
                            :readonly="false&&ifDisabled()"
                            background-color="green accent-1"
                            v-model="reg.EMAIL" :rules="emailRules" hide-details="auto" _outlined dense _clearable
                            label="Email | Email"
                            _autocomplete="email"
                            _name="email"
                            type="text"
                            autocomplete="off"
                            append-icon="edit"
                            maxlength=50>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4">
                        <v-text-field class="mx-0"
                            :readonly="false&&ifDisabled()"
                            background-color="green accent-1"
                            v-model="reg.MOVIL" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Teléfono | Phone (+34)"
                            _autocomplete="tel"
                            autocomplete="off"
                            name="tel"
                            type="text"
                            append-icon="edit"
                            maxlength=15>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                        <v-text-field class="mx-0"
                            :readonly="ifDisabled()"
                            v-model="reg.DOMICILIO" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Dirección | Address" 
                            type="text"
                            autocomplete="street-address"
                            name="street-address"
                            maxlength=150>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="8">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.POB" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Población | City" 
                            autocomplete="home city"
                            name="homecity"
                            type="text"
                            maxlength=150>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.CP" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Cod.post. | Zip code" 
                            type="text"
                            autocomplete="postal-code"
                            name="postalcode"
                            maxlength=15>
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field
                            :readonly="ifDisabled()"
                            v-model="reg.PRV" :rules="fieldRules" hide-details="auto" _outlined dense _clearable
                            label="Provincia | Province" 
                            type="text"
                            autocomplete="address-level1"
                            name="addresslevel1"
                            maxlength=150>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete _outlined hide-details dense v-model="reg.CODPAIS_FK"
                            :readonly="ifDisabled()"
                            :items="objPaises"
                            item-value="PAIS_PK"
                            item-text="NOMUK"
                            label="País | Country"
                            :rules="fieldRulesCero">
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>

            <v-dialog v-model="showOtrasDirecciones">
                <v-card>
                    <v-card-title class="subtitle-1">Seleccione | Select</v-card-title>
                    <template v-for="(item, index) in alojados">
                        <v-list-item @click="onSelectAlojado(item)" v-if="item.ID_CLIENTE !== reg.ID_CLIENTE" :key="index + 1">
                            <v-list-item-content class="pa-0 ma-0">
                                <v-list-item-title>
                                    <p class="ma-1">{{Lib_Desnullize(item.NOMBRE) + ' ' + Lib_Desnullize(item.APELLIDO1) + ' ' + Lib_Desnullize(item.APELLIDO2)}}</p>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <p class="ma-1">&nbsp;&nbsp;&nbsp;{{Lib_Desnullize(item.DOMICILIO)}}</p>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <p class="ma-1">&nbsp;&nbsp;&nbsp;{{Lib_Desnullize(item.CP) + ' ' + Lib_Desnullize(item.POB) + ' ' + Lib_Desnullize(item.PRV)}}</p>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index<alojados.length-1" :key="-index - 1"></v-divider>
                    </template>
                </v-card>
            </v-dialog>
        </v-card-text>

        <DialogYesNo
            :visible="dlgConfirmVisible"
            tittle="Atención | Attention"
            message="¿Desea realmente eliminar el alojado? | Do you really want to delete the guest?"
            @responseyes="onResponseYes"
            @responseno="onResponseNo">
        </DialogYesNo>

        <FormSignature ref="formSignature"></FormSignature>

    </v-card>
</template>

<script>

import Lib from '@/lib/lib';
import Objects from '@/lib/objects';
import Bbdd from '@/lib/bbdd';
import WidgetDate from '@/widgets/WidgetDate';
import DialogYesNo from '@/views/DialogYesNo';
import FormSignature from '@/views/FormSignature';

export default {
    name: 'WidgetGuest',

    components: {
        WidgetDate,
        DialogYesNo,
        FormSignature
    },

    props: {
        reg: {},
        counter: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        modeCliente: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            disableValidation: false,

            objDocType: Objects.ObjDocType,
            objSexo: Objects.ObjSexo,
            objTipoPersona: Objects.ObjTipoPersona,

            dniRules: [
                (v) => this.getDisableValidation() || (!!v && Lib.ValidarNif(v)) || 'DNI incorrecto | Wrong ID'
            ],

            fieldRules: [ 
                (v) => this.getDisableValidation() || !!v || 'requerido | required'
            ],

            dateRules: [
                (v) => this.getDisableValidation() || Lib.AnyStringToDate(v, 1900, true) !== '' || 'fecha incorrecta | wrong date' + ' (DD-MM-YYYY)'
            ],

            emailRules: [
                (v) => this.getDisableValidation() || !!v || 'email requerido | email required',
                (v) => this.getDisableValidation() || /.+@.+/.test(v) || 'email incorrecto | wrong email'
            ],

            fieldRulesCero: [
                v => {
                    //return v.toString()!=='0' || GlobalTrans('requerido');
                    return this.getDisableValidation() || !!v || 'requerido | required';
                }
            ],

            alojados: [],
            objPaises: Objects.ObjPaises,

            dlgConfirmVisible: false,

            validado: false,
            showOtrasDirecciones: false
        }
    },

    watch: {
        'reg.TIPO_DOCUMENTO': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.NUM_DOCUMENTO': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.FECHA_EXPED': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.FECHA_CADUC': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.SEXO': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.TIPO_PERSONA': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.NOMBRE': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.APELLIDO1': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.APELLIDO2': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.NACIMIENTO': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.EMAIL': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.DOMICILIO': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.POB': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.CP': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.PRV': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        },
        'reg.CODPAIS_FK': function () {
            this.$nextTick(function () {
                this.onValidateData (true);
            });
        }
    },

    methods: {

        ifDisabled () {

            return !this.modeCliente;
        },

        onQuitarAlojado () {

            this.dlgConfirmVisible = true;
        },

        getDisableValidation () {

            // No quieren validación en modo recepción ...
            return !this.modeCliente || this.disableValidation;
        },

        onResponseYes () {

            Bbdd.DeleteAlojado (this.reg.RESERVA_PK, this.reg.ID_CLIENTE, (err) => {
                if (err === null) {
                    this.dlgConfirmVisible = false;
                    this.$emit('reload');
                }
            });
        },

        onResponseNo () {

            this.dlgConfirmVisible = false;
        },

        sihaceFaltaFirma () {

            return this.reg.TIPO_PERSONA === "A" && this.reg.FIRMA_DIG === null;
        },

        siRepetirFirma () {

            return this.reg.TIPO_PERSONA === "A";
        },

        loadData () {

            Bbdd.GetRawClientesReserva ( this.reg.RESERVA_PK, (err, data) => {

                if (err === null) {

                    this.alojados = data;
                }
            });
        },

        loadFirma () {

            try {
                var image = document.getElementById('imageFirma' + this.counter);

                if (this.reg.FIRMA_DIG !== null) {

                    var bytes = new Uint8Array(this.reg.FIRMA_DIG.data);

                    
                    image.src = 'data:image/png;base64,' + Lib.Encode(bytes);
                }
            } catch (error) {
                console.log(error);
            }
        },

        onValidateData (insideWidget) {

            if (this.reg.TIPO_PERSONA === 'B') {

                this.disableValidation = true;
                this.validado = true;

                if (insideWidget) {
                    this.$emit('onValidationChanged');
                }

                return this.validado;
            }

            this.disableValidation = false;
            this.validado = this.$refs.form.validate();

            if (insideWidget) {
                this.$emit('onValidationChanged');
            }

            return this.validado;
        },

        save (callback) {

            const r = Lib.SimpleClone(this.reg);

            if (Lib.AnyStringToDate(r.FECHA_EXPED, 1900, true) === '') {
                r.FECHA_EXPED = null;
            } else {
                r.FECHA_EXPED = Lib.AnyDateToStrDateYYYYMMDD(r.FECHA_EXPED);
            }

            if (Lib.AnyStringToDate(r.FECHA_CADUC, 1900, true) === '') {
                r.FECHA_CADUC = null;
            } else {
                r.FECHA_CADUC = Lib.AnyDateToStrDateYYYYMMDD(r.FECHA_CADUC);
            }
            
            if (Lib.AnyStringToDate(r.NACIMIENTO, 1900, true) === '') {
                r.NACIMIENTO = null;
            } else {
                r.NACIMIENTO = Lib.AnyDateToStrDateYYYYMMDD(r.NACIMIENTO);
            }

            if (Lib.AnyStringToDate(r.FECHA_ENTRADA, 1900, true) === '') {
                r.FECHA_ENTRADA = null;
            } else {
                r.FECHA_ENTRADA = Lib.AnyDateToStrDateYYYYMMDD(r.FECHA_ENTRADA);
            }

            if (Lib.AnyStringToDate(r.FECHA_SALIDA, 1900, true) === '') {
                r.FECHA_SALIDA = null;
            } else {
                r.FECHA_SALIDA = Lib.AnyDateToStrDateYYYYMMDD(r.FECHA_SALIDA);
            }

            delete r.FIRMA_DIG;

            if (typeof r.NUM_DOCUMENTO === 'undefined' || r.NUM_DOCUMENTO === null) {
                r.NUM_DOCUMENTO = '';
            }

            r.NUM_DOCUMENTO = r.NUM_DOCUMENTO.replace(/\s/g, '').trim().toUpperCase();

            Bbdd.UpdateClientesReservaAndCliente (r, (/*err, data*/) => {
                callback ();
            });
        },

        onSelectAlojado (item) {

            this.reg.DOMICILIO = item.DOMICILIO;
            this.reg.POB = item.POB;
            this.reg.CP = item.CP;
            this.reg.PRV = item.PRV;
            this.reg.CODPAIS_FK = item.CODPAIS_FK;
            this.reg.EMAIL = item.EMAIL;

            this.showOtrasDirecciones = false;
        },

        onClickSignature () {

            this.save ( () => {

                this.$refs.formSignature.show (this.reg.LOPDFIRMADO,

                    (err, data) => {

                        Bbdd.UpdateSignature (this.reg.ID_CLIENTE, data, (/*err, data*/) => {

                            this.reg.LOPDFIRMADO = true;

                            Bbdd.UpdateLopdFirmadoCliente (this.reg.CLIENTE_PK, true, (/*err, data*/) => {

                                Bbdd.GetRawClienteReserva (this.reg.ID_CLIENTE,  (err, dataC) => {

                                    if (err === null && dataC.length > 0) {

                                        this.reg.FIRMA_DIG = dataC[0].FIRMA_DIG;
                                        this.loadFirma ();
                                    }
                                });
                            });
                        });

                    }, () => {

                    });
            });
        }
    }
};
</script>