<template>
    <v-app>
        <v-card>
            <v-app-bar dense color="primary" dark>
                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/logo.png" transition="scale-transition" width="40" @click="showClickHere=true"/>
                <v-spacer></v-spacer><p class="pa-0 ma-0 caption">v11</p>
                <v-chip color="success" v-if="test">MODE TEST</v-chip>
                <v-btn v-if="modeRecepcion" color="primary" depressed @click="onShowSettings"><v-icon>settings</v-icon></v-btn>
                <v-btn v-if="modeRecepcion" color="primary" depressed @click="onShowComplejos"><v-icon>domain</v-icon></v-btn>
            </v-app-bar>

            <v-container fluid style="height:calc(100vh - 48px);overflow-y:auto">

            <v-row v-if="!modeRecepcion && !modeCliente" class="text-center text-xs-center">
                <p class="text-center ma-0 ma-4 pa-4" style="width:100%"><v-icon large color="error">block</v-icon></p>
                <p class="text-center ma-0 ma-4 pa-4 headline" style="width:100%">Acceso no autorizado / Forbidden access</p>
            </v-row>

            <v-row v-if="modeRecepcion&&showClickHere" @click="showClickHere=false;tryLoadRecepcion()" style="height:calc(100vh - 48px);">
                <v-card style="width:100%">
                    <v-card-text style="height:100%">
                        <v-layout align-center justify-center style="height:100%">
                            <v-card hover color="success">
                                <v-card-text class="pa-4" color="success">
                                    <p class="text-center display-4 ma-4 white--text"><b>Click</b> to start</p>
                                </v-card-text>
                            </v-card>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-row>

            <v-row v-if="modeRecepcion&&!showClickHere" class="mb-6" no-gutters>
                <v-col  offset-md="3" md="6" xs="12">
                    <v-card class="pa-2">
                        <p class="text-center ma-0 headline">{{nombreComplejo}}</p>
                        <v-divider class="my-2"></v-divider>
                        <v-list rounded>
                            <v-fab-transition>
                                <v-btn style="margin-top:64px;" @click="tryLoadRecepcion"
                                  elevation="2"
                                  fab absolute large top right color="success"><v-icon>sync</v-icon>
                              </v-btn>
                            </v-fab-transition>
                            <template  v-for="(item, i) in dataReservas">
                                <v-list-item @click="onClickItem(item)" :key="i+1">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><b>{{item.ID_RESERVA}}</b></v-list-item-title>
                                        <v-list-item-subtitle class="mt-2">{{item.CLIENTE_VIAJA}}</v-list-item-subtitle >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="my-2" :key="-i"></v-divider>
                            </template>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <FormConfig ref="formconfig"></FormConfig>
        <FormGuests ref="formguests"></FormGuests>
        <DialodTwoOptions ref="dialogTwoOptions"></DialodTwoOptions>
        <DialodThreeOptions ref="dialogThreeOptions"></DialodThreeOptions>
    </v-card>
  </v-app>
</template>

<script>
import Bbdd from '@/lib/bbdd';
import Lib from '@/lib/lib';
import Objects from '@/lib/objects';
import FormConfig from '@/views/FormConfig';
import FormGuests from '@/views/FormGuests';
import DialodTwoOptions from '@/views/DialogTwoOptions';
import DialodThreeOptions from '@/views/DialogThreeOptions';
const config = require('./config/config');

export default {
    name: 'App',

    components: {
        FormConfig,
        FormGuests,
        DialodTwoOptions,
        DialodThreeOptions
    },

    data: () => ({

        modeRecepcion: false,
        modeCliente: false,

        nombreComplejo: '',
        dataReservas: [],

        test: config.TEST,
        showClickHere: false
    }),

    mounted() {
        this.loadComponent();
    },

    methods: {

        tryLoadRecepcion () {

            if (typeof localStorage.idComplejo !== 'undefined' && typeof localStorage.idRecepcion !== 'undefined' && localStorage.idComplejo !== '' && localStorage.idRecepcion !== '') {

                this.modeRecepcion = true;
                this.nombreComplejo = '';

                Bbdd.GetComplejo(localStorage.idComplejo, (err, data)=>{
                    if (err === null && data.length > 0) {
                        this.nombreComplejo = data[0].N_COMPLEJO;
                    }
                });

                Bbdd.GetPaperlessReservasPendientes(localStorage.idComplejo, localStorage.idRecepcion, (err, data) => {

                    this.dataReservas = data;
                });
            }
        },

        onClickItem (item) {

            this.$refs.formguests.show (item.ID_COMPLEJO, item.RESERVA_PK, item.ROWID, item.EMAIL, false, () => {
                this.showClickHere = true;
                this.tryLoadRecepcion ();
            },  () => {
                this.showClickHere = true;
            });
        },

        onShowSettings () {

            this.$refs.formconfig.show(
                ()=>{
                    this.tryLoadRecepcion ();
                },
                ()=>{
                    this.tryLoadRecepcion ();
                });
        },

        onShowComplejos () {

            const complejos = localStorage.idComplejo2.split(',');
            let name1 = '';
            let name2 = '';
            let name3 = '';

            if (complejos.length === 2) {

                Bbdd.GetComplejo(complejos[0], (err, data1) => {
                    
                    if (err === null && data1.length > 0) {

                        name1 = data1[0].N_COMPLEJO;

                        Bbdd.GetComplejo(complejos[1], (err, data2)=> {

                            if (err === null && data2.length > 0) {

                                name2 = data2[0].N_COMPLEJO;

                                this.$refs.dialogTwoOptions.show("Seleccione el complejo", name1, name2, 
                                
                                    (val) => {

                                        localStorage.idComplejo = complejos[val];

                                        this.tryLoadRecepcion ();
                                    },
                                    () => {

                                        this.tryLoadRecepcion ();
                                    }
                                );
                            }
                        });
                    }
                });
            } else if (complejos.length === 3) {

                Bbdd.GetComplejo(complejos[0], (err, data1) => {
                    
                    if (err === null && data1.length > 0) {

                        name1 = data1[0].N_COMPLEJO;

                        Bbdd.GetComplejo(complejos[1], (err, data2)=> {

                            if (err === null && data2.length > 0) {

                                name2 = data2[0].N_COMPLEJO;

                                Bbdd.GetComplejo(complejos[2], (err, data3)=> {

                                    if (err === null && data3.length > 0) {

                                        name3 = data3[0].N_COMPLEJO;

                                        this.$refs.dialogThreeOptions.show("Seleccione el complejo", name1, name2, name3,
                                        
                                            (val) => {

                                                localStorage.idComplejo = complejos[val];

                                                this.tryLoadRecepcion ();
                                            },
                                            () => {

                                                this.tryLoadRecepcion ();
                                            }
                                        );
                                    }
                                });
                            }
                        });
                    }
                });
            } else {

                alert('Si va a gestionar la firma de 2 complejos, debe indicar sus ID separados por coma: ID1,ID2');
            }
        },

        loadComponent () {

            Bbdd.GetRawPaises ((err, data) => {

                Objects.ObjPaises = data;
            });

            const id = Lib.GetParameterByName ('id');
            if (id !== '') {
                localStorage.ID = id;
                this.modeCliente = true;

                this.$refs.formguests.show(-1, -1, -1, "", true, () => {

                }, null);

            } else {
                localStorage.ID = '';

                this.tryLoadRecepcion();

                this.showClickHere = true;

                const mode = Lib.GetParameterByName ('mode');
                if (mode === 'config') {
                    this.onShowSettings ();
                }
            }
        }
    }
};
</script>
