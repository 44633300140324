import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import Lib from '@/lib/lib.js';

Vue.mixin({
    data () {
        return {
            CUR: '€'
        };
    },
    methods: {
        /*NameCol (data) {
            const nc = Lib.NameCol();
            
            return data[nc];
        },
        NAMECOL () {
            const nc = Lib.NameCol();
            return nc;
        },
        PropNameCol (object, fieldPrefix) {

            return this.PROPNAMECOL(object, fieldPrefix);
        },
        PROPNAMECOL (object, fieldPrefix) {

            let pref = '';
            if (typeof (fieldPrefix) !== 'undefined') {
                pref = fieldPrefix;
            }
            const fullFieldName = pref + this.NAMECOL();
            const ret = object[fullFieldName] || '';
        
            return ret;
        },
        Trans (s) {
            return Dic.Trans (s);
        },
        */
        Lib_AnyDateToStrDateDDMMYYYY (p) {
            return Lib.AnyDateToStrDateDDMMYYYY (p);
        },
        Lib_AnyStringToDate (p) {
            return Lib.AnyStringToDate (p);
        },
        Lib_Desnullize (s) {
            return Lib.Desnullize (s);
        },

        /*
        Lib_AnyDateToStrDateTimeDDMMYYYYHHMM (p) {
            return Lib.AnyDateToStrDateTimeDDMMYYYYHHMM (p);
        },
        Lib_AnyDateToStrDateDDMM (p) {
            return Lib.AnyDateToStrDateDDMM (p);
        },
        Lib_FormatFloatThousands (p) {
            return Lib.FormatFloatThousands (p);
        },
        Lib_FormatFloat (f) {
            return Lib.FormatFloat (f);
        },
        Lib_AnyDateToStrTimeHHMM (d) {
            return Lib.AnyDateToStrTimeHHMM (d);
        },
        Lib_GetHHMMFromString (s) {
            return Lib.GetHHMMFromString (s);
        },
        Lib_FormatFloatThousands (f) {
            return Lib.FormatFloatThousands (f);
        },
        Lib_FormatFloatThousandsDecimal (f) {
            return Lib.FormatFloatThousandsDecimal (f);
        }*/
    }
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
