const axios = require('axios');

const config = require('../config/config');

function postGeneric(data, callback) {

    if (config.TEST) {
        data.isTest = 1
    }
    data.id = localStorage.ID;
    // data.Lang = localStorage.LANG;

    axios.post(config.URL, JSON.stringify(data))
        .then(function (response) {
        callback (null, response.data);
    })
        .catch(function (error) {
        callback (error, null);
    });
}

exports.GetVersion = function (callback) {

    postGeneric ({"f": "getVersion"}, callback);
}

exports.GetComplejo = function (idComplejo, callback) {

    postGeneric ({"f": "getComplejo", idComplejo}, callback);
}

exports.GetPaperlessReservasPendientes = function (idComplejo, idRecepcion, callback) {

    postGeneric ({"f": "getPaperlessReservasPendientes", idComplejo, idRecepcion}, callback);
}

exports.GetRawReserva = function (idComplejo, reservaPk, callback) {

    postGeneric ({"f": "getRawReserva", idComplejo, reservaPk}, callback);
}

exports.GetRawClientesReserva = function (reservaPk, callback) {

    postGeneric ({"f": "getRawClientesReserva", reservaPk}, callback);
}

exports.GetRawPaises = function (callback) {

    postGeneric ({"f": "getRawPaises"}, callback);
}

exports.InsertarAlojado = function (reservaPk, callback) {

    postGeneric ({"f": "insertarAlojado", reservaPk}, callback);
}

exports.DeleteAlojado = function (reservaPk, idCliente, callback) {

    postGeneric ({"f": "deleteAlojado", reservaPk, idCliente}, callback);
}

exports.UpdateClientesReservaAndCliente = function (reg, callback) {

    postGeneric ({"f": "updateClientesReservaAndCliente", reg}, callback);
}

exports.GetRgpdText = function (idComplejo, lang, callback) {

    postGeneric ({"f": "getRgpdText", idComplejo, lang}, callback);
}

exports.UpdateSignature = function (idCliente, base64Img, callback) {

    postGeneric ({"f": "updateSignature", idCliente, base64Img}, callback);
}

exports.GetRawClienteReserva = function (idCliente, callback) {

    postGeneric ({"f": "getRawClienteReserva", idCliente}, callback);
}

exports.UpdateLopdFirmadoCliente = function (clientePk, lopdFirmado, callback) {

    postGeneric ({"f": "updateLopdFirmadoCliente", clientePk, lopdFirmado}, callback);
}

exports.GetCanDoCheckin = function (reservaPk, callback) {

    postGeneric ({"f": "getCanDoCheckin", reservaPk}, callback);
}

exports.SetPaperlessCheckInProcesado = function (idComplejo, reservaPk, rowId, callback) {

    postGeneric ({"f": "setPaperlessCheckInProcesado", idComplejo, reservaPk, rowId}, callback);
}
